<template>
  <span>
    <el-button @click="dialogShow" class="dialog-btn" size="small">申请退款</el-button>
    <el-dialog
      title="退款申请"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <el-form ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules" class="ruleForm">
        <el-form-item label="商品选择" prop="goods" class="ruleForm__table">
          <p class="ruleForm__table--title">
            只显示可以申请退款的商品，如果已经申请了退款的商品是不显示的
          </p>
          <div class="table-box">
            <span class="ruleForm__table--allCheck" v-if="type !== 'GLOBAL'">全部</span>
            <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              max-height="435px"
            >
              <el-table-column type="selection" width="55" v-if="type !== 'GLOBAL'">
              </el-table-column>
              <el-table-column label="商品信息" width="300px" header-align="center">
                <template slot-scope="scope">
                  <div class="ruleForm__table--namebox">
                    <img :src="scope.row.imgUrl" class="ruleForm__table--img" />
                    <p class="ruleForm__table--name">{{ scope.row.commodityName }}</p>
                    <p>规格名称: {{ scope.row.commoditySpecValue }}</p>
                    <p>规格标识: {{ scope.row.skuId }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="数量" prop="quantity" align="center"> </el-table-column>
              <el-table-column label="实收金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.receiptAmount }}</template>
              </el-table-column>
              <el-table-column label="发货状态" align="center">
                  <template slot-scope="scope">
                      {{ scope.row.deliveryStatusName }}
                      <!-- 部分发货需要显示发货数量 -->
                      <span v-if="scope.row.deliveryStatus === 'SUB_DELIVERY'">({{ scope.row.deliveryNum }}件)</span>
                  </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item label="退款类型" prop="type" class="ruleForm__type">
          <div class="ruleForm__type--box">
            <div
              class="ruleForm__type--div"
              @click="typeChange('REFUND_ONLY')"
              :class="ruleForm.type === 'REFUND_ONLY' ? 'checked' : ''"
            >
              <p class="ruleForm__type--title">仅退款</p>
              <p class="ruleForm__type--text">未收到货或跟商家协商一致</p>
            </div>
            <div
              v-show="status !== 'PAID'"
              class="ruleForm__type--div"
              @click="typeChange('REFUND_RETURN')"
              :class="ruleForm.type === 'REFUND_RETURN' ? 'checked' : ''"
            >
              <p class="ruleForm__type--title">退款退货</p>
              <p class="ruleForm__type--text">已收到货需退货</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="退款原因" prop="reason">
          <el-select v-model="ruleForm.reason">
            <el-option :label="item" :value="item" v-for="item in reason" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退款金额" prop="applyRefundAmount">
          <el-input v-model="ruleForm.applyRefundAmount" autocomplete="off"></el-input>
          <div>最多可退金额：￥{{ maxRefundAmount }}</div>
        </el-form-item>
        <el-form-item label="补充说明">
          <el-input placeholder="请输入"
            type="textarea"
            v-model.trim="ruleForm.description"
            maxlength="240"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="btnLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </span>
</template>
<script>
import { create, calculateMaxRefundMoney, cancelCreditBackRemind } from '@/api/order/refund';
import { validateMoney2 } from '@/utils/validate';
export default {
  props: {
    goodList: {},
    orderId: '',
    status: '',
    type: '',
  },
  data() {
    const validateGoods = (rule, value, callback) => {
      if (this.multipleSelection.length < 1) {
        callback(new Error('请选择商品'));
      } else {
        callback();
      }
    };
    const validateMoney = (rule, value, callback) => {
      if (!validateMoney2(value)) {
        callback('数据有误，请重新输入');
        return;
      }
      if (value > this.maxRefundAmount) {
        callback(`最多可退金额为${this.maxRefundAmount}元`);
        return;
      }
      callback();
    };
    return {
      dialogFormVisible: false,
      ruleForm: {
        type: 'REFUND_ONLY',
        reason: '错拍',
        applyRefundAmount: 0,
        description: '',
      },
      rules: {
        goods: [{ required: true, validator: validateGoods, trigger: 'blur' }],
        type: [{ required: true }],
        reason: [{ required: true }],
        applyRefundAmount: [
          { required: true, message: '退款金额格式错误', trigger: 'blur' },
          { validator: validateMoney, trigger: 'blur' },
        ],
      },
      maxRefundAmount: 0,
      // typeOption: [
      //   { value: 'REFUND_ONLY', title: '仅退款', text: '未收到货或跟商家协<br/>商一致' },
      //   { value: 'REFUND_RETURN', title: '退款退货', text: '已收到货需退货' },
      // ],
      reason: ['错拍', '七天无理由退货', '质量问题'],
      tableData: [],
      multipleSelection: [],
      btnLoading: false,
    };
  },

  methods: {
    dialogShow() {
      this.dialogFormVisible = true;
      this.tableData = [];
      if (this.type === 'GLOBAL') {
        this.tableData = this.goodList;
        this.multipleSelection = this.goodList;
        this.calculateMaxRefundMoney();
      } else {
        for (const item of this.goodList) {
          if (item.refundStatus === 'NO_REFUND') {
            this.tableData.push(item);
          }
        }
      }
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
    // 最大退款金额
    calculateMaxRefundMoney() {
      const orderDetailIds = this.multipleSelection.map(item => {
        return item.id;
      });
      const data = {
        orderId: this.orderId,
        orderDetailIds,
      };
      calculateMaxRefundMoney(data).then(res => {
        this.ruleForm.applyRefundAmount = res.data;
        this.maxRefundAmount = res.data;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.calculateMaxRefundMoney();
    },
    typeChange(val) {
      this.ruleForm.type = val;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const data = { ...this.ruleForm };
          data.orderId = this.orderId;
          data.orderDetailIds = this.multipleSelection.map(item => {
            return item.id;
          });
          if (data.type === 'REFUND_RETURN') {
            for (const i of this.multipleSelection) {
              // 只有部分发货/已发货可以选择退货退款
              if (i.deliveryStatus !== 'SUB_DELIVERY' && i.deliveryStatus !== 'DELIVERED') {
                this.$message({
                  showClose: true,
                  message: '退货退款只能选择已发货商品',
                  type: 'warning',
                });
                return;
              }
            }
          }

          this.btnLoading = true;
          // 判断订单使用了返利支付，且返利存在在退款时间已失效情况
          const res = await cancelCreditBackRemind({ orderId: this.orderId, orderDetailIds: data.orderDetailIds, refundAmount: data.applyRefundAmount });
          const { expireAmount } = res.data;
          if (expireAmount) {
            const isConfirm = await this.$confirm(
              `本次退款将有${expireAmount}元返利由于过期失效无法使用，请慎重操作`,
              '重要提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            ).catch(() => false);
            if (!isConfirm) {
              this.btnLoading = false;
              return;
            }
          }

          delete data.goods;
          create(data)
            .then(res => {
              if (res.code === '0') {
                this.$emit('handClickRefund');
                this.dialogFormVisible = false;
              }
            })
            .finally(() => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-btn {
  width: 80px;
  color: #ab0033;
  border: 1px solid #ab0033;
}
.ruleForm {
  /deep/.el-radio-button {
    margin-right: 30px;
  }
  &__type {
    &--box {
      display: flex;
    }
    &--div {
      min-width: 156px;
      margin-right: 30px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
    }
    &--title {
      font-size: 14px;
      font-weight: bold;
      color: #666;
    }
    &--text {
      line-height: 20px;
      color: #999;
    }
  }
  &__table {
    &--title {
      margin-bottom: 10px;
    }
    .table-box {
      position: relative;
    }
    &--allCheck {
      position: absolute;
      left: 29px;
      z-index: 9;
      color: #333;
      font-size: 12px;
      top: 13px;
      line-height: 23px;
    }
    &__nameBox {
      width: 338px;
      padding-left: 10px;
    }
    &--img {
      width: 70px;
      height: 70px;
      float: left;
      margin-right: 10px;
    }
    &--name {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .table {
      border: 1px solid #e5e5e5;
      .el-table {
        font-size: 12px;
        color: #333;
        /deep/ th {
          padding: 8px 0;
          color: #333;
          background-color: #f8f8f8 !important;
          border-color: #e5e5e5;
          font-weight: normal;
        }
        /deep/ .el-table__row {
          height: 110px;
          td {
            border-bottom: 1px dashed #e5e5e5;
          }
        }
      }
    }
  }
  .el-input,
  .el-select {
    width: 300px;
  }
  .el-textarea {
    width: 600px;
  }
}
.dialog-footer {
  text-align: center;
}

.checked {
  border: 1px solid #ab0033;
  p {
    color: #ab0033;
  }
}
</style>
